<!-- 保障协议 -->
<template>
  <div class="main">
    <div class="content">
      <div>
        <div class="top">{{ form.q_agreement_name }}</div>
        <!-- 内容头部-甲方 -->
        <div class="party">
          <div class="partyA">
            <span class="inpLeft">甲方：</span>
            <el-input v-model="form.q_company_a_party" class="inp"></el-input>
          </div>
          <div class="partyA">
            <span class="inpLeft">身份证号：</span>
            <el-input v-model="form.q_company_a_card" class="inp"></el-input>
          </div>
          <div class="partyA">
            <span class="inpLeft">联系电话：</span>
            <el-input v-model="form.q_company_a_phone" class="inp"></el-input>
          </div>
          <div class="partyA">
            <span class="inpLeft">筑龙用户名：</span>
            <span class="inpSpanB">{{ form.q_company_a_zl }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft">订单号：</span>
            <span class="inpSpanB">{{ form.q_company_a_order }}</span>
          </div>
        </div>
        <!-- 内容头部-乙方 -->
        <div class="party">
          <div class="partyA">
            <span class="inpLeft">乙方：</span>
            <span class="inpSpanB blod">{{ form.q_company_b_party }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft">网址：</span>
            <span class="inpSpanB">{{ form.q_company_b_website }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft">地址：</span>
            <span class="inpSpanB">{{ form.q_company_b_address }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft">联系电话：</span>
            <span class="inpSpanB">{{ form.q_company_b_phone }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft">邮箱：</span>
            <span class="inpSpanB">{{ form.q_company_b_email }}</span>
          </div>
        </div>
      </div>

      <div class="agreement_content" v-html="q_agreement_content"></div>

      <div class="content_btm">
        <div class="btn_left">
          <div class="partyA">
            <span class="inpLeft leftCenter">甲方</span>
            <span>：</span>
            <el-input v-model="form.q_company_a_party" class="inp"></el-input>
          </div>
          <!-- <div class="partyA">
            <span class="inpLeft leftCenter"></span>
            <span></span>
          </div> -->
          <div class="partyA">
            <span class="inpLeft leftCenter">联系人</span>
            <span>：</span>
            <el-input v-model="form.q_company_a_contacts" class="inp"></el-input>
          </div>
          <div class="partyA">
            <span class="inpLeft leftCenter">联系电话</span>
            <span>：</span>
            <el-input v-model="form.q_company_a_phone" class="inp"></el-input>
          </div>
          <div class="partyA">
            <span class="inpLeft leftCenter">日期</span>
            <span>：</span>
            <span class="aline">
              <span class="shortInpB">{{ btmDateA.year }}</span>
              <span>年</span>
              <span class="shortInpB">{{ btmDateA.month }}</span>
              <span>月</span>
              <span class="shortInpB">{{ btmDateA.date }}</span>
              <span>日</span>
            </span>
          </div>
        </div>
        <div class="btn_right">
          <div class="partyA">
            <span class="inpLeft leftCenter">乙方</span>
            <span>：</span>
            <span class="blod">{{ form.q_company_b_party }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft leftCenter">盖章</span>
            <span>：</span>
            <span class="btmInpSpan"></span>
          </div>
          <div class="partyA">
            <span class="inpLeft leftCenter">联系人</span>
            <span>：</span>
            <span class="btmInpSpan">{{ form.q_company_b_contacts }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft leftCenter">联系电话</span>
            <span>：</span>
            <span class="btmInpSpan">{{ form.q_company_b_phone }}</span>
          </div>
          <div class="partyA">
            <span class="inpLeft leftCenter">日期</span>
            <span>：</span>
            <span class="aline">
              <span class="shortInpB">{{ btmDateB.year }}</span>
              <span>年</span>
              <span class="shortInpB">{{ btmDateB.month }}</span>
              <span>月</span>
              <span class="shortInpB">{{ btmDateB.date }}</span>
              <span>日</span>
            </span>
          </div>
          <!-- v-if="form.q_company_b_sign"  -->
          <div class="company_b_sign">
            <img :src="form.q_company_b_sign" alt="">
          </div>
        </div>
      </div>

      <el-button v-show="form.status===0" type="primary" class="button" @click="save">确定</el-button>
    </div>
  </div>
</template>

<script>
import { getAgreementInfo, confirmAgreement } from '@/api/agreement.js'
export default {
  data() {
    return {
      name: '',
      form: {
        order_id: '',
        order_sn: '',
        lesson_id: '',
        agreement_id: '',
        q_agreement_name: '',
        q_company_a_party: '', // 甲方名称
        q_company_a_card: '', // 甲方身份证号
        q_company_a_phone: '', // 甲方手机号
        q_company_a_contacts: '', // 甲方联系人
        q_company_a_zl: '', // 甲方筑龙用户名
        q_company_a_order: '', // 甲方订单号
        q_company_b_party: '', // 乙方名称
        q_company_b_website: '', // 乙方网址
        q_company_b_address: '', // 乙方地址
        q_company_b_phone: '', // 乙方联系电话
        q_company_b_contacts: '', // 乙方联系人
        q_company_b_email: '', // 乙方邮箱
        q_company_b_sign: '', // 乙方印章
        status: 0
      },
      q_agreement_content: '', // 协议内容
      btmDateA: {
        year: '',
        month: '',
        date: ''
      },
      btmDateB: {
        year: '',
        month: '',
        date: ''
      }
    }
  },
  mounted() {
    this.agreementInfo()
  },
  methods: {
    agreementInfo() {
      var str = this.$route.query.listQuery
      var obj = JSON.parse(str)
      getAgreementInfo(obj).then(res => {
        const result = res.result
        this.q_agreement_content = result.q_agreement_content
        this.form = result
        if (this.form.q_agreement_content) {
          this.form.q_agreement_content = ''
        }
        this.btmDateA.year = result.q_company_a_time.slice(0, 4)
        this.btmDateA.month = result.q_company_a_time.slice(5, 7)
        this.btmDateA.date = result.q_company_a_time.slice(8, 10)
        this.btmDateB.year = result.q_company_b_time.slice(0, 4)
        this.btmDateB.month = result.q_company_b_time.slice(5, 7)
        this.btmDateB.date = result.q_company_b_time.slice(8, 10)
      })
    },
    save() {
      confirmAgreement(this.form).then(res => {
        if (res.errNo === 0) {
          const msg = res.result ? res.result : '保存成功'
          this.$message.success(msg)
          this.$router.go(-1)
        }
      })
    },
    getBtmDate() {
      var date = new Date()
      this.btmDate.year = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      this.btmDate.month = m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      this.btmDate.date = d
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 1200px;
  margin: auto;
  margin-top: 60px;
  background-color: white;
  padding-bottom: 30px;
}
.content{
  padding: 30px 20px 0 20px;
}
.top{
  font-size: 24px;
  border-bottom: 1px solid #bbbbbb;
  text-align: center;
  padding-bottom: 5px;
}
.party{
  margin-top: 20px;
}
.partyA{
  padding: 5px 0 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.general{
  font-size: 14px;
  font-weight: bold;
}
.inpLeft{
  display: inline-block;
  // width: 90px;
  font-weight: bold;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}
.leftCenter{
  width: 90px;
  text-align: justify;
  text-align-last: justify;
}
.inpSpan{
  display: inline-block;
  width: 210px;
  border-bottom: 1px solid #5f4f4f;
  height: 20px;
  line-height: 20px;
}
.inpSpanB{
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.btmInpSpan{
  display: inline-block;
  width: 210px;
  border-bottom: 1px solid #bbbbbb;
  height: 20px;
  line-height: 20px;
  text-align: left;
}

.blod{
  font-weight: bold;
}
.inp{
  margin-left: 6px;
  width: 210px;
  border-bottom: 1px solid #bbbbbb;
  /deep/.el-input__inner{
    padding: 0 !important;
    border: none;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    border-radius: 0;
  }
}
.shortInp{
  margin-left: 6px;
  width: 52px;
  border-bottom: 1px solid #bbbbbb;
  /deep/.el-input__inner{
    padding: 0 !important;
    border: none;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    border-radius: 0;
    text-align: center;
  }
}
.shortInpB{
  display: inline-block;
  width: 56px;
  border-bottom: 1px solid #bbbbbb;
  height: 20px;
  line-height: 20px;
}
.aline{
  text-align: center;
}

.content_btm{
  margin: 40px 0 0 0;
  display: flex;
  justify-content: flex-start;
}
.btn_left{
  position: relative;
  height: 250px;
  padding: 20px 0;
  span{
    font-size: 14px;
  }
}
.btn_right{
  margin-left: 200px;
  position: relative;
  height: 250px;
  padding: 20px 0;
  span{
    font-size: 14px;
  }
  .company_b_sign {
    position: absolute;
    right: 10px;
    bottom: 20px;
    width: 200px;
    img {
      width: 100%;
    }
  }
}
.button{
  width: 200px;
  margin: 30px auto 0 auto;
}
.agreement_content {
  padding: 20px 0;
  text-align: left;
  font-size: 18px;
  color: #333333;
}
</style>
